// src/App.tsx

import React, { useEffect } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  createTheme,
  CssBaseline,
  PaletteMode,
  ThemeProvider,
} from "@mui/material";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { routes as appRoutes } from "./routes";
import { ColorContext } from "./ColorContext";

import NavbarNotLogged from "./components/NavbarNotLogged";
import DinamicNavbar from "./components/dinamicNavBar/Index";

import Footer from "./components/Footer";
import NotLogged from "./pages/NotLogged";

import { darkTheme } from "./themes/dark";
import { lightTheme } from "./themes/light";
import { LOGIN_API } from "./api/login";
import { BLUESHELL_JWT_TOKEN_REFRESH } from "./utilities/general";
import SignIn from "./pages/Login2";

function App() {
  const [mode, setMode] = React.useState<PaletteMode>("light");
  const [cookiesPresent, setCookiesPresent] = React.useState(false);
  const [checkingUser, SetCheckingUser] = React.useState(true);
  const [userData, SetUserData] = React.useState(null);

  useEffect(() => {
    const checkLogin = async () => {
      try {
        await LOGIN_API.whoami().then((response) => {
          if (
            response &&
            response.status === 200 &&
            response.data.status === "FOUND"
          ) {
            sessionStorage.setItem(BLUESHELL_JWT_TOKEN_REFRESH, "true");
            SetUserData(response.data.data);
            setCookiesPresent(true);
            SetCheckingUser(false);
          } else {
            sessionStorage.removeItem("userData");
            sessionStorage.removeItem(BLUESHELL_JWT_TOKEN_REFRESH);
            SetUserData(null);
            setCookiesPresent(false);
            SetCheckingUser(false);
          }
        });
      } catch (error: any) {
        sessionStorage.removeItem("userData");
        sessionStorage.removeItem(BLUESHELL_JWT_TOKEN_REFRESH);
        SetUserData(null);
        setCookiesPresent(false);
        SetCheckingUser(false);
      }
    };
    checkLogin();
  }, []);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) =>
          prevMode === "light" ? "dark" : "light"
        );
      },
    }),
    []
  );

  const theme = React.useMemo(
    () => createTheme(mode === "light" ? lightTheme : darkTheme),
    [mode]
  );
  return (
    <ColorContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <Box height="100vh" display="flex" flexDirection="column">
          <Router>
            {cookiesPresent ? (
              <>
                <DinamicNavbar userData={userData} />
              </>
            ) : (
              <NavbarNotLogged />
            )}
            <Routes>
              {appRoutes.map((route) => (
                <Route
                  key={route.key}
                  path={route.path}
                  element={
                    checkingUser ? (
                      <Backdrop
                        sx={{
                          color: "#000",
                          background: "rgba(255,255,255, 1)",
                          zIndex: (theme: any) => theme.zIndex.drawer + 1,
                        }}
                        open={true}
                        onClick={() => {}}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    ) : !cookiesPresent && route.key === "login" ? (
                      <SignIn />
                    ) : cookiesPresent ? (
                      <route.component />
                    ) : (
                      <NotLogged />
                    )
                  }
                />
              ))}
            </Routes>
            {!checkingUser && <Footer />}
          </Router>
        </Box>
      </ThemeProvider>
    </ColorContext.Provider>
  );
}

export default App;
