// src/pages/Home.tsx

import React, { ReactElement, FC, useState, useEffect } from "react";
import { Box, Grid, Paper, Stack, styled, Typography } from "@mui/material";

// import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import DashboardMenuComponent from "../components/statisticheMarina/DashboardMenu";
import OccupancySection from "../components/statisticheMarina/OccupancySection";
import RicaviSectionComponent from "../components/statisticheMarina/RicaviSection";
import FatturatoSectionComponent from "../components/statisticheMarina/FatturatoSection";
import { STATISTICHE_MARINA_API } from "../api/statistiche_marina";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  margin: "0 0 .9em 0",
  textAlign: "left",
  color: theme.palette.text.primary,
}));

const StatisticheMarina: FC<any> = (): ReactElement => {
  const [dashboardData, SetDashboardData] = useState<{
    page: { title: string; subtitle: string };
    years: [];
  }>({ page: { title: "", subtitle: "" }, years: [] });

  const [year, setYear] = React.useState("");
  const [yearsList, setYearsList] = React.useState<[]>([]);
  useEffect(() => {
    STATISTICHE_MARINA_API.getDashboard(year)
      .then((resp) => {
        resp.status === "FOUND" && SetDashboardData(resp.data);
        setYearsList(resp.data.years);
        setYear(resp.input.year);
      })
      .catch((err) => {
        console.log("RICAVI ERROR", err);
      });
  }, [year]);

  const handleChange = (event: any) => {
    // set the new year start all Api for refresh data referred to period
    setYear(event.target.value);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "secondary.main",
        margin: 1,
        padding: 2,
      }}
    >
      <Grid container>
        <Grid item xs={1.5}>
          <DashboardMenuComponent
            year={year}
            yearsList={yearsList}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={10.5}>
          <Item>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <Typography variant="h5" sx={{ paddingTop: ".2em" }}>
                <b>{dashboardData.page.title}</b> {dashboardData.page.subtitle}
              </Typography>
            </Stack>
            <OccupancySection year={year} />
            <RicaviSectionComponent year={year} />
            <FatturatoSectionComponent year={year} />
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StatisticheMarina;
