import axios from "axios";
import { BLUESHELL_JWT_TOKEN_REFRESH } from "../utilities/general";

import { getLanguage } from "../hooks/getLanguage";

const API_HOST = process.env.REACT_APP_API_END_POINT;

const instance = axios.create({
  baseURL: API_HOST,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": getLanguage(),
  },
});

instance.interceptors.request.use(
  (config: any) => {
    return config;
  },
  (error: any) => {
    console.log("Error in request interceptor", error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res: any) => {
    return res;
  },
  async (err: any) => {
    const originalConfig = err.config;

    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await refreshToken();
          const { token, refresh_token } = rs.data;
          if (token && refresh_token) {
            window.sessionStorage.setItem(BLUESHELL_JWT_TOKEN_REFRESH, "true");
          } else {
            window.sessionStorage.removeItem(BLUESHELL_JWT_TOKEN_REFRESH);
          }
          // instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

          return instance(originalConfig);
        } catch (error: any) {
          console.log("ERORR", error);
          if (error.response && error.response.status === 422) {
            console.log("REFRESH TOKEN Expired!!", error.response);
            sessionStorage.removeItem("userData");
            sessionStorage.removeItem(BLUESHELL_JWT_TOKEN_REFRESH);
            return Promise.reject(error.response.data);
          }

          return Promise.reject(error);
        }
      }

      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }

    return Promise.reject(err);
  }
);
const refreshToken = () => {
  return instance.post("/refresh");
};

// example
const signin = (user: string, pw: string) => {
  return instance.post("/auth/signin", {
    username: user,
    password: pw,
  });
};
export default instance;
