// src/componetns/Footer.tsx

import React, { FC, ReactElement, useEffect, useState } from "react";
import { Button, Chip } from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { teal } from "@mui/material/colors";

const Chipscomponent: FC<any> = (props): ReactElement => {
  const [filter, SetFilter] = useState([]);
  useEffect(() => {
    SetFilter(props.items);
  }, [props.items]);
  interface IFilter {
    field?: string;
    operator?: string;
    id?: string;
    value?: string;
  }
  // console.log("FILTERS", filter);
  const checkValue = (object: { value: string }) => {
    return object.value !== "0" && object.value === "";
  };
  const filtersApplied = filter.filter(checkValue).length;
  // console.log("filtersApplied", filtersApplied);
  return (
    <>
      {filter.length > 0 && (
        <Button
          sx={{
            width: "18em",
            backgroundColor: teal[200],
            border: `1px solid ${teal[400]}`,
            marginTop: 0.5,
            marginLeft: 5,
            textAlign: "center",
            borderRadius: 2,
          }}
          color="primary"
          startIcon={<FilterAltOffIcon />}
          onClick={() => {
            props.clearFilters();
          }}
        >
          Rimuovi tutti i Filtri&nbsp;<b>({filter.length - filtersApplied})</b>
        </Button>
      )}
      {/* {filter.length > 0 &&
        filter.map((item: IFilter, index) => (
          <Chip
            key={index}
            label={`${item.field}: ${item.value}`}
            color="success"
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              marginTop: 1,
              fontWeight: "bold",
            }}
          />
        ))} */}
    </>
  );
};

export default Chipscomponent;
