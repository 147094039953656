// src/componetns/Footer.tsx

import React, { FC, ReactElement } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { Copyright as CopyrightIcon } from "@mui/icons-material";
import { BLUESHELL_UI_VERSION } from "../utilities/general";

export const Footer: FC = (): ReactElement => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
    >
      <Container maxWidth="lg">
        <Grid container direction="row" textAlign="center">
          <Grid item xs={12}>
            <Typography variant="h5">BLUESHELL</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CopyrightIcon />
              <Typography variant="subtitle1">
                {`${new Date().getFullYear()} - Innovamarine by Elint SA - ${BLUESHELL_UI_VERSION}`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
