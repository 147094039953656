// src/pages/About.tsx

import React, { ReactElement, FC } from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";

const Home: FC<any> = (): ReactElement => {
  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={1}>
            <Typography variant="h3">
              node_ENV: "{process.env.NODE_ENV}"
            </Typography>
          </Grid>
          <Grid item xs={12} md={1}>
            <Typography variant="h3">
              API_HOST: "{process.env.REACT_APP_API_END_POINT}"
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack spacing={2} direction="row">
          <Button variant="text">btn example</Button>
          <Button variant="contained">btn example</Button>
          <Button variant="outlined">btn example</Button>
        </Stack>
      </Box>
    </>
  );
};

export default Home;
