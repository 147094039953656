import instance from "./interceptor";

export const PAGEDATA_API = {
  getPageData: async (params?: string) => {
    const urlPath = `${params}`;
    const result = await instance.get(urlPath);
    if (result.data.status === "FOUND") {
      return result.data;
    }
  },
};
