import instance from "./interceptor";

export const LOGIN_API = {
  authData: {},
  state: {},
  BlueshellJwt: "",
  BlueshellJwtRefresh: "",
  userData: {},

  getUserData: async () => {
    return sessionStorage.getItem("userData");
  },

  saveUserData: (obj: object) => {
    const data = JSON.stringify(obj);
    sessionStorage.setItem("userData", data);
    LOGIN_API.userData = obj;
  },

  tryLogin: async (email: string, password: string) => {
    // TODO: future implementation...
  },

  whoami: async () => {
    const result = await instance.get("/whoami");
    result.data.status === "FOUND" && LOGIN_API.saveUserData(result.data.data);
    return result;
  },
};
