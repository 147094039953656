import { useState, ReactElement, FC } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

const DeleteUserActionItem: FC<any> = (props): ReactElement => {
  const [open, setOpen] = useState(false);
  const deleteUser = (props: any) => {
    console.log("delete user", props.params);
  };
  const customerName = props.params.row.fullname || "";
  return (
    <>
      <GridActionsCellItem {...props} onClick={() => setOpen(true)} />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ background: "#FF9800" }}>
          <DialogTitle id="alert-dialog-title">
            Delete user "{customerName}"?
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{ color: "#000", fontWeight: "bold" }}
            >
              This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ background: "#fff", fontWeight: "bold" }}>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button
              onClick={() => {
                setOpen(false);
                deleteUser(props);
              }}
              color="warning"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default DeleteUserActionItem;
