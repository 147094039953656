import React, { FC, ReactElement } from "react";
import {
  Box,
  Divider,
  Grid,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";

export const FatturatoSkeleton: FC = (): ReactElement => {
  const theme = useTheme();
  return (
    <>
      <Typography variant="h5" component="div" width={400}>
        <Skeleton
          sx={{
            bgcolor: theme.palette.customBackground.light,
            opacity: 0.5,
          }}
        />
      </Typography>
      <Divider sx={{ mb: 1 }} />
      <Grid container wrap="nowrap">
        <Box sx={{ width: "50%", marginRight: 0.5 }}>
          <Skeleton
            sx={{
              bgcolor: theme.palette.customBackground.light,
              opacity: 0.5,
            }}
            animation="wave"
            variant="rounded"
            width="100%"
            height={214}
          />
        </Box>
        <Box sx={{ width: "25%", marginRight: 0.5 }}>
          <Skeleton
            sx={{
              bgcolor: theme.palette.customBackground.light,
              opacity: 0.5,
            }}
            animation="wave"
            variant="rounded"
            width="100%"
            height={214}
          />
        </Box>
        <Box sx={{ width: "25%", marginRight: 0.5 }}>
          <Skeleton
            sx={{
              bgcolor: theme.palette.customBackground.light,
              opacity: 0.5,
            }}
            animation="wave"
            variant="rounded"
            width="100%"
            height={214}
          />
        </Box>
      </Grid>
    </>
  );
};
