import { useEffect, useState, ReactElement, FC } from "react";
import { CardContent, TextField, Typography } from "@mui/material";

import { GridFilterModel } from "@mui/x-data-grid-premium";
import { NameObject } from "../../interfaces";
import { blueGrey } from "@mui/material/colors";

const FilterByInputTextComponent: FC<any> = (props): ReactElement => {
  const [nameError, SetNameError] = useState<false | true>(false);
  const [value, setValue] = useState<string>("");
  const [filter, setFilter] = useState<GridFilterModel>(props.filterModel);

  const inputTextFilterParams = props.params;

  useEffect(() => {
    if (props.filterModel.items.length > 0) {
      props.filterModel.items.map((item: NameObject) => {
        if (item.field === inputTextFilterParams.fieldName && item.value) {
          setValue(item.value);
        }
        return null;
      });
    } else {
      setValue("");
    }

    setFilter(props.filterModel);
    return;
  }, [props, inputTextFilterParams.fieldName]);

  const handleNameChange = (evt: any) => {
    setValue(evt.target.value);
    // Errore non gestito al momento
    // evt.target.value.length < 3 && SetNameError(true);

    const stringToSearch = filter.items.filter(
      (item) => item.field === inputTextFilterParams.fieldName
    );
    if (stringToSearch.length > 0 && evt.target.value.length > 0) {
      stringToSearch[0].value = evt.target.value;
    } else if (stringToSearch.length > 0 && evt.target.value.length === 0) {
      filter.items = filter.items.filter(
        (item) => item.field !== inputTextFilterParams.fieldName
      );
    } else {
      filter.items.push({
        field: inputTextFilterParams.fieldName,
        operator: inputTextFilterParams.operator,
        id: `${inputTextFilterParams.operator}_${inputTextFilterParams.fieldName}`,
        value: evt.target.value,
      });
    }
    setFilter(filter);
    props.onFilterChange(filter);
  };
  return (
    <CardContent sx={{ width: "100%" }}>
      <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
        {inputTextFilterParams.title}
      </Typography>
      <TextField
        color="primary"
        fullWidth={true}
        placeholder={inputTextFilterParams.placeholder}
        error={nameError}
        id={inputTextFilterParams.id}
        label={`${
          nameError
            ? inputTextFilterParams.inputErrorLabel
            : inputTextFilterParams.placeholder
        }`}
        helperText={`${nameError ? "error messagge here!!!" : ""}`}
        size="small"
        value={value}
        onChange={handleNameChange}
        InputLabelProps={{
          style: {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: "100%",
            color: blueGrey[700],
          },
        }}
      />
    </CardContent>
  );
};

export default FilterByInputTextComponent;
