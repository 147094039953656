// src/pages/Home.tsx

import React, { ReactElement, FC, useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import StatisticCard from "../../components/StatisticCard";

import { StatisticsCardProps, OccupazioneSection } from "../../interfaces";

import { STATISTICHE_MARINA_API } from "../../api/statistiche_marina";

const OccupancySection: FC<any> = (props): ReactElement => {
  const [loadingContent, SetLoadingContent] = useState(true);
  const [occupazioneSection, SetOccupazioneSection] =
    useState<OccupazioneSection>({});
  const year = props.year || "";
  useEffect(() => {
    STATISTICHE_MARINA_API.getStatistiche(year)
      .then((resp) => {
        resp.status === "FOUND" && SetOccupazioneSection(resp.data);
      })
      .catch((err) => {
        console.log("OCCUPANCY ERROR", err);
      })
      .finally(() => SetLoadingContent(false));
  }, [year]);

  const replaceText = (str: string, strToReplace: string, strNew: string) => {
    const newStr = str.replace(strToReplace, strNew);
    return newStr;
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "secondary.main",
        margin: "0 0 0 .5em",
        // padding: 1,
      }}
    >
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme: any) => theme.zIndex.drawer + 1,
        }}
        open={loadingContent}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {Object.keys(occupazioneSection) &&
      Object.keys(occupazioneSection).length > 0 ? (
        <>
          <Typography variant="h6" sx={{ marginTop: 1, fontWeight: 600 }}>
            {occupazioneSection.dataTypeLabel}
          </Typography>
          <Divider
            component="div"
            role="presentation"
            sx={{ marginBottom: 1 }}
          />
          <Grid container spacing={1}>
            {occupazioneSection.cards &&
              occupazioneSection.cards.map(
                (item: StatisticsCardProps, index: number) => (
                  <Grid item xs={2} sm={4} md={2} key={index}>
                    <StatisticCard
                      title={replaceText(item.title, "Percentuale", "%")}
                      description={item.description}
                      mainValue={item.mainValue}
                      otherInfo={item.otherInfo}
                      otherInfoValue={item.otherInfoValue}
                    />
                  </Grid>
                )
              )}
          </Grid>
        </>
      ) : (
        "No data to show"
      )}
    </Box>
  );
};

export default OccupancySection;
