export const HELPERS = {
  formatDate: (dateString: any) => {
    if (dateString) {
      return new Date(dateString);
    }
  },
  formatCurrency: (currencyString: any) => {
    if (currencyString) {
      return currencyString.formattedValue
    }
  },
  formatUnknow: (typeString: any) => {
    console.log(typeString)
    console.log(`Error: Type "${typeString.value}" is not present in the helper functions.`);
  },
};
