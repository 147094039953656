import * as React from "react";
import { AppBar, Box, Toolbar } from "@mui/material";

import { styled } from "@mui/material/styles";

import { SwitchModeButton } from "./SwitchModeButton";
import bs_white_no_payoff from "../assets/img/logo-blueshell-bianco-orizzontale-no-payoff.png";

export default function NavbarNotLogged() {
  const Div = styled("div")(({ theme }) => ({
    ...theme.typography.button,
    color: theme.palette.text.primary,
    backgroundColor: "transparent",
    lineHeight: 0,
  }));

  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <Div>
            <img src={bs_white_no_payoff} width="140" alt="BlueShell V5" />
          </Div>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" }, marginLeft: 2 }}>
            <SwitchModeButton />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
