// src/pages/Home.tsx

import React, { ReactElement, FC, useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

import FatturatoCard from "./FatturatoCard";
import { FatturatoSkeleton } from "./FatturatoSkeleton";

import { FatturatoCardProps, FatturatoSection } from "../../interfaces";

import { STATISTICHE_MARINA_API } from "../../api/statistiche_marina";

const FatturatoSectionComponent: FC<any> = (props): ReactElement => {
  const [loadingContent, SetLoadingContent] = useState(true);
  const [fatturaroSection, SetFatturaroSection] = useState<FatturatoSection>(
    {}
  );

  const year = props.year || "";
  useEffect(() => {
    SetFatturaroSection({});
    STATISTICHE_MARINA_API.getFatture(year)
      .then((resp) => {
        resp.status === "FOUND" && SetFatturaroSection(resp.data);
      })
      .catch((err) => {
        console.log("RICAVI ERROR", err);
      })
      .finally(() => SetLoadingContent(false));
  }, [year]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "secondary.main",
        margin: 1,
        padding: 2,
      }}
    >
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme: any) => theme.zIndex.drawer + 1,
        }}
        open={loadingContent}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Typography variant="h6" sx={{ marginTop: 2, fontWeight: 600 }}>
        {fatturaroSection.dataTypeLabel}
      </Typography>
      <Divider component="div" role="presentation" sx={{ marginBottom: 1 }} />
      {Object.keys(fatturaroSection).length > 0 ? (
        <Grid container spacing={1}>
          {fatturaroSection.cards &&
            fatturaroSection.cards.map(
              (item: FatturatoCardProps, index: number) => (
                <FatturatoCard
                  key={index}
                  title={item.title}
                  description={item.description}
                  mainValue={item.mainValue}
                  iva={item.iva}
                  itemXs={item.mainCard ? 6 : 3}
                  index={index}
                  data={item.data}
                  otherInfo={item.otherInfo}
                />
              )
            )}
        </Grid>
      ) : (
        <FatturatoSkeleton />
      )}
    </Box>
  );
};

export default FatturatoSectionComponent;
