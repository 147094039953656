import React, { ReactElement, FC, useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  Link,
  ListItemText,
  MenuList,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";

import { CONFIGS } from "../../configurations";
import { STATISTICHE_MARINA_API } from "../../api/statistiche_marina";
import DinamicIcon from "../dinamicNavBar/DinamicIcon";
import { MenuDashboardItem } from "../../interfaces";

const DashboardMenuComponent: FC<any> = (props): ReactElement => {
  const { year, yearsList, handleChange } = props;
  const [menuDashboard, SetMenuDashboard] = useState<[]>([]);
  const [loadingContent, SetLoadingContent] = useState(false);

  useEffect(() => {
    document.title = `Dashboard - ${CONFIGS.webApptitle}`;
    STATISTICHE_MARINA_API.getDashboardMenu()
      .then((resp) => {
        resp.status === "FOUND" && SetMenuDashboard(resp.data);
      })
      .catch((err) => {
        console.log("RICAVI ERROR", err);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleUnload = () => {
      SetLoadingContent(!loadingContent);
    };
    window.addEventListener("unload", handleUnload);
    return () => {
      window.removeEventListener("unload", handleUnload);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme: any) => theme.zIndex.drawer + 10000,
        }}
        open={loadingContent}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper sx={{ width: "94% !important" }}>
        <FormControl sx={{ m: 1, width: "92%" }} size="small">
          <InputLabel sx={{ color: "primary.main" }} id="select-year-label">
            Year
          </InputLabel>
          <Select
            labelId="select-year-label"
            id="select-year"
            value={year}
            onChange={handleChange}
            label="Year"
          >
            {yearsList.length > 0 &&
              yearsList.map((year: string) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {Object.keys(MenuItem).length > 0 &&
          menuDashboard.map((itemMenu: MenuDashboardItem, index: number) => {
            return (
              <React.Fragment key={`mainContainer-${index}`}>
                <Box>
                  <Box>
                    <Box sx={{ display: "flex", padding: ".5em" }}>
                      <DinamicIcon icon={itemMenu.icon} />
                      <Typography
                        variant="body2"
                        color="primary.dark"
                        sx={{ marginLeft: ".4em", marginTop: ".2em" }}
                      >
                        <b>{itemMenu.label}</b>
                      </Typography>
                    </Box>
                  </Box>
                  {itemMenu.elements.length > 0 ? (
                    <MenuList dense sx={{ paddingTop: 0 }}>
                      {itemMenu.elements.map((element: any, index: number) => {
                        return (
                          // Override MenuItem display: flex
                          <MenuItem sx={{ display: "block" }} key={index}>
                            <ListItemText>
                              <Typography
                                variant="subtitle2"
                                color="text.secondary"
                                sx={{
                                  position: "absolute",
                                  left: 0,
                                  width: "100%",
                                  textTransform: "capitalize",
                                  backgroundColor: "primary.main",
                                  paddingLeft: "1.2em",
                                  fontWeight: "bold",
                                }}
                                noWrap
                              >
                                &bull; {element.title}
                              </Typography>
                            </ListItemText>
                            <MenuList
                              dense
                              sx={{
                                margin: "1.5em 0 0",
                                padding: 0,
                              }}
                            >
                              {element.links.map((link: any, index: number) => {
                                return (
                                  <MenuItem
                                    key={`link-key${index}`}
                                    sx={{
                                      marginLeft: 0,

                                      padding: "0 0 0 .7em !important",
                                    }}
                                  >
                                    <Typography variant="inherit" noWrap>
                                      <Link
                                        href={link.href}
                                        underline="none"
                                        variant="subtitle2"
                                        onClick={() =>
                                          SetLoadingContent(!loadingContent)
                                        }
                                      >
                                        &rsaquo; {link.label}
                                      </Link>
                                    </Typography>
                                  </MenuItem>
                                );
                              })}
                            </MenuList>
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  ) : null}
                </Box>
                <Divider />
              </React.Fragment>
            );
          })}
      </Paper>
    </>
  );
};

export default DashboardMenuComponent;
