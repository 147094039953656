import {
  BLUESHELL_LANGUAGE_KEY,
  BLUESHELL_DEFAULT_LANGUAGE,
} from "../utilities/general";

export const getLanguage = () => {
  const language =
    sessionStorage.getItem(BLUESHELL_LANGUAGE_KEY) ||
    BLUESHELL_DEFAULT_LANGUAGE;
  return language;
};
