import { Suspense } from "react";
import * as MUIicon from "@mui/icons-material";

const DinamicIcon = ({ icon }: any) => {
  const Icon = MUIicon[icon as keyof typeof MUIicon];
  return (
    <Suspense fallback="...">
      <Icon />
    </Suspense>
  );
};

export default DinamicIcon;
