// src/routes.ts

// pages
import Login from "./pages/Login";
import SignIn from "./pages/Login2";
import Home from "./pages/Home";
import About from "./pages/About";
import StatisticheMarina from "./pages/StatisticheMarina";
import DinamicDataGrid from "./pages/DinamicDataGrid";
import CommonDataGrid from "./pages/CommonDataGrid";

import {
  AnalyticsTwoTone as AnalyticsTwoToneIcon,
  SettingsTwoTone as SettingsTwoToneIcon,
  CottageTwoTone as CottageTwoToneIcon,
} from "@mui/icons-material";

// other
import { FC } from "react";
import NotLogged from "./pages/NotLogged";

// interface
interface Route {
  key: string;
  title: string;
  path: string;
  enabled: boolean;
  component: FC<{}>;
  iconCompoenent?: FC<{}>;
}

export const routes: Array<Route> = [
  {
    key: "login",
    title: "Login",
    path: "/login",
    enabled: false,
    component: Login,
  },
  {
    key: "login2",
    title: "Login2",
    path: "/login2",
    enabled: false,
    component: SignIn,
  },
  {
    key: "home-route",
    title: "Home",
    path: "/",
    enabled: true,
    component: Home,
    iconCompoenent: CottageTwoToneIcon,
  },
  {
    key: "dashboard-statistics",
    title: "Statistiche Marina",
    path: "/dashboard",
    enabled: true,
    component: StatisticheMarina,
    iconCompoenent: AnalyticsTwoToneIcon,
  },
  {
    key: "about-route",
    title: "Tech details",
    path: "/about",
    enabled: true,
    component: About,
    iconCompoenent: SettingsTwoToneIcon,
  },
  {
    key: "notLogged-route",
    title: "Not logged Page",
    path: "/notlogged",
    enabled: false,
    component: NotLogged,
  },
  {
    key: "dinamicDatagrid-route",
    title: "Anagrafiche clienti",
    path: "/dinamicdatagrid",
    enabled: true,
    component: DinamicDataGrid,
  },
  {
    key: "commonDataGrid-route",
    title: "Received from Api",
    path: "/admin/:gridId",
    enabled: true,
    component: CommonDataGrid,
  },
];
