import { Menu, MenuItem } from "@mui/material";
import { URL_FOR_LOGOUT } from "../../utilities/general";
import { getLanguage } from "../../hooks/getLanguage";
interface profileMenuProps {
  profileAnchorEl?: null | HTMLElement;
  profileMenuId?: string;
  isProfileMenuOpen: boolean;
  handleMenuClose: () => void;
  handleChangeLanguage?: () => void;
}

export default function ProfileMenu({
  profileAnchorEl,
  profileMenuId,
  isProfileMenuOpen,
  handleMenuClose,
  handleChangeLanguage,
}: profileMenuProps) {
  const handleLogout = () => {
    window.location.replace(URL_FOR_LOGOUT);
  };
  const language = getLanguage();
  return (
    <Menu
      anchorEl={profileAnchorEl}
      id={profileMenuId}
      keepMounted
      open={isProfileMenuOpen}
      onClose={handleMenuClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          p: 1,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem>Language:</MenuItem>
      {language && language === "en_EN" ? (
        <MenuItem data-lan="it_IT" onClick={handleChangeLanguage}>
          Italiano
        </MenuItem>
      ) : (
        <MenuItem data-lan="en_EN" onClick={handleChangeLanguage}>
          English
        </MenuItem>
      )}
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );
}
