// src/pages/Home.tsx

import { ReactElement, FC, useEffect } from "react";
import { Box, Button, Typography, Stack } from "@mui/material";
import { CONFIGS } from "../configurations";

const Home: FC<any> = (): ReactElement => {
  useEffect(() => {
    document.title = `Home - ${CONFIGS.webApptitle}`;
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h3">Home</Typography>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack spacing={2} direction="row">
          <Button variant="text">btn example</Button>
          <Button variant="contained">btn example</Button>
          <Button variant="outlined">btn example</Button>
        </Stack>
      </Box>
    </>
  );
};

export default Home;
