import { useState, useContext } from "react";
// import { useCookies } from "react-cookie";
import {
  Card,
  Box,
  CardActions,
  CardContent,
  CircularProgress,
  Button,
  Typography,
  TextField,
} from "@mui/material";

import { Send } from "@mui/icons-material";

// import { useSnackbar } from "notistack";

import { LOGIN_API } from "../api/login";
import { GlobalContext } from "../store/global";

const Login = () => {
  document.title = "Login";
  const context = useContext(GlobalContext);
  console.log("CONTEXT", context);
  // Hook for manipulating locally scoped state.
  const [state, setState] = useState({
    isBarVisible: false,
    loginBtnDisabled: false,
  });

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setState({ ...state, isBarVisible: true, loginBtnDisabled: true });
    LOGIN_API.tryLogin(email, password).then((response) => {
      console.log("===>", response);
    });
  };

  return (
    <Box component="form" autoComplete="off" onSubmit={handleSubmit}>
      <Card>
        <CardContent>
          <Typography variant="h5" mb={1} gutterBottom component="h1">
            Sign in
          </Typography>
          <TextField
            required
            autoFocus
            label="E-mail"
            inputProps={{
              inputMode: "text",
              pattern: "[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}",
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            required
            autoFocus
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </CardContent>
        <CardActions>
          <Button
            endIcon={<Send />}
            variant="contained"
            color="primary"
            type="submit"
            disabled={state.isBarVisible}
          >
            Login
          </Button>
        </CardActions>
        {state.isBarVisible && (
          <div className="verifying">
            <p>Verifying...</p>
            <CircularProgress />
          </div>
        )}
      </Card>
    </Box>
  );
};

export default Login;
