// src/componetns/Footer.tsx

import React, { FC, ReactElement } from "react";
import { Card, CardContent, CardActions, Typography } from "@mui/material";
import { StatisticsCardProps } from "../interfaces";

export const StatisticCard: FC<StatisticsCardProps> = (props): ReactElement => {
  const title = props.title;
  const description = props.description;
  const mainValue = props.mainValue;
  const otherInfo = props.otherInfo;
  const otherInfoValue = props.otherInfoValue;
  const cardActions = props.cardActions;
  return (
    <Card sx={{ minHeight: 200 }} variant="outlined">
      <CardContent>
        <Typography variant="h5" component="div">
          <b>{title}</b>
        </Typography>
        <Typography variant="caption" sx={{ mb: 1.5 }}>
          {description}
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontSize: 30, fontWeight: "bold" }}
          color="evidenceColor.main"
        >
          {mainValue}
        </Typography>
        <Typography variant="caption">
          {otherInfo ? `${otherInfo} ` : null}
          {otherInfoValue ? <b>{otherInfoValue}</b> : null}
        </Typography>
      </CardContent>
      {cardActions && <CardActions>...</CardActions>}
    </Card>
  );
};

export default StatisticCard;
