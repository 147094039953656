import { useEffect, useState, ChangeEvent } from "react";
import {
  FormLabel,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import { grey } from "@mui/material/colors";
import { GridFilterModel } from "@mui/x-data-grid-premium";
import { NameObject } from "../../interfaces";

export default function ControlledRadioButtonsGroup(props: any) {
  const [value, setValue] = useState("");
  const [radioButton, SetRadioButton] = useState([]);
  const inputTextFilterParams = props.params;
  const [filter, setFilter] = useState<GridFilterModel>(props.filterModel);

  useEffect(() => {
    SetRadioButton(props.options);
    if (props.filterModel.items.length > 0) {
      props.filterModel.items.map((item: NameObject) => {
        if (item.field === inputTextFilterParams.fieldName && item.value) {
          setValue(item.value);
        }
        return null;
      });
    } else {
      setValue("");
    }
  }, [props, inputTextFilterParams.fieldName]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = (event.target as HTMLInputElement).value;
    setValue(newValue);
    const stringToSearch = filter.items.filter(
      (item) => item.field === inputTextFilterParams.fieldName
    );
    if (stringToSearch.length > 0 && newValue.length > 0) {
      stringToSearch[0].value = newValue;
    } else if (stringToSearch.length > 0 && newValue.length === 0) {
      filter.items = filter.items.filter(
        (item) => item.field !== inputTextFilterParams.fieldName
      );
    } else {
      filter.items.push({
        field: inputTextFilterParams.fieldName,
        operator: inputTextFilterParams.operator,
        id: inputTextFilterParams.fieldName,
        value: newValue,
      });
    }
    setFilter(filter);
    props.onFilterChange(filter);
  };

  return (
    <FormControl
      sx={{
        margin: "0.5em auto",
        background: "#eee",
        padding: "0.1em 1em",
        border: `1px solid ${grey[400]}`,
        borderRadius: 1,
        textAlign: "left",
      }}
    >
      <FormLabel
        id={inputTextFilterParams.id}
        sx={{ color: grey[700], fontSize: "0.9em" }}
      >
        {inputTextFilterParams.title}
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby={inputTextFilterParams.id}
        name={inputTextFilterParams.id}
        value={value}
        onChange={handleChange}
      >
        {radioButton &&
          radioButton.length > 0 &&
          radioButton.map(
            (item: { type: string; label: string }, index: number) => {
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: grey[700],
                        "&.Mui-checked": {
                          color: grey[700],
                        },
                      }}
                    />
                  }
                  value={item.type}
                  label={item.label}
                />
              );
            }
          )}
      </RadioGroup>
    </FormControl>
  );
}
