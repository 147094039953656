// src/componetns/Footer.tsx

import React, { FC, ReactElement } from "react";
import { Card, CardContent, Typography, useTheme } from "@mui/material";
import { RicaviCardProps } from "../interfaces";

export const RicaviCard: FC<RicaviCardProps> = (props): ReactElement => {
  const theme = useTheme();
  const title = props.title;
  const mainValue = props.mainValue;
  return (
    <Card variant="outlined">
      <CardContent
        sx={{
          background: theme.palette.customBackground.main,
          padding: "0.5em",
          paddingBottom: "0.5em !important",
        }}
      >
        <Typography variant="subtitle1" component="div">
          <b>{title}</b>
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontSize: 24, fontWeight: "bold" }}
          color="bwColor.main"
        >
          {mainValue}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default RicaviCard;
