export const mockedTotalObj = {
  status: "FOUND",
  data: [
    {
      "type": "totals",
      "id": " ",
      "fullname": null,
      "active": null,
      "type_id": null,
      "category_id": null,
      "greeting": null,
      "cliente_tipo": null,
      "cliente_data_nascita": null,
      "cliente_luogo_nascita": null,
      "cliente_indirizzo": null,
      "cliente_citta": null,
      "cliente_cap": null,
      "cliente_provincia": null,
      "cliente_nazione": "1",
      "billing_header": null,
      "billing_address": null,
      "billing_zip": null,
      "billing_city": null,
      "billing_province": null,
      "billing_country": null,
      "billing_individual": "1",
      "cliente_telefono1": null,
      "phone_desc": null,
      "cliente_tipo_telefono1": null,
      "cliente_telefono2": null,
      "mobile_desc": null,
      "cliente_tipo_telefono2": null,
      "cliente_telefono3": null,
      "other_contact_desc": null,
      "cliente_tipo_telefono3": null,
      "cliente_email": null,
      "cliente_descrizione_email": null,
      "cliente_codice_fiscale": null,
      "cliente_partita_iva": null,
      "vat_number_country": null,
      "cliente_documento": null,
      "cliente_numero_documento": null,
      "cliente_rifiuta_comunicazioni": null,
      "cliente_note": null,
      "vat_contract": null,
      "vat_contract_description": null,
      "vat_service": null,
      "vat_service_description": null,
      "country": null,
      "country_blacklist": "0",
      "nationality": null,
      "phone1_code": null,
      "mobile_code": null,
      "phone3_code": null,
      "email2": null,
      "email2_desc": null,
      "email3": null,
      "email3_desc": null,
      "email_billing": null,
      "massive_email_invoice_automatic": null,
      "massive_email_invoice_manual": null,
      "massive_email_unpaid_report": null,
      "massive_email_identification_expiration": null,
      "massive_email_vector_insurance": null,
      "massive_email_vector_identification_expiration": null,
      "www": null,
      "language": "it",
      "customer_total": "€ 1.250.345,00",
      "customer_discounted_total": "€ 1.250.345,00",
      "customer_total_billed": "€ 1.250.345,00",
      "customer_total_unbilled": "€ 250.345,00",
      "customer_total_paid": "€ 450.345,00",
      "customer_total_reversed": "€ 100.000,00",
      "customer_total_unpaid": "€ 1.000.150,00",
      "customer_total_yard_debt": "€ 11.000.250,00",
      "customer_total_mooring_debt": "€ 250.000,00",
      "customer_total_debt": "€ 1.500.000,00",
      "contract_tax_rate_id": null,
      "order_tax_rate_id": null,
      "contract_due_date_id": null,
      "default_payment_transaction_account_id": null,
      "default_agency_percentage": "€ 220.200,00",
      "individual": "0",
      "individual_firstname": null,
      "individual_lastname": null,
      "individual_birthdate": null,
      "individual_birthcity": null,
      "individual_birthprovince": null,
      "individual_birthcountry": null,
      "individual_gender": null,
      "alternative_code": null,
      "alternative_code_series": null,
      "alternative_code2": null,
      "privacy_date": null,
      "privacy_consent": "1",
      "advertising_consent": null,
      "third_party_consent": null,
      "logital_profile": null,
      "create_user": null,
      "update_user": null,
      "create_time": null,
      "update_time": null,
      "my_total": {
          "value": null,
          "formattedValue": null
      },
      "my_discounted_total": {
          "value": null,
          "formattedValue": null
      },
      "my_total_billed": {
          "value": null,
          "formattedValue": null
      },
      "my_total_unbilled": {
          "value": null,
          "formattedValue": null
      },
      "customer_type": {
          "id": 233,
          "name": null,
          "color": null
      },
      "customer_category": {
          "id": 281,
          "name": null,
          "color": null
      }
    }
  ]
}