import { ThemeOptions } from "@mui/material";
declare module "@mui/material/styles" {
  interface Palette {
    evidenceColor: Palette["primary"];
    bwColor: Palette["primary"];
    customBackground: Palette["primary"];
  }
  interface PaletteOptions {
    evidenceColor: PaletteOptions["primary"];
    bwColor: PaletteOptions["primary"];
    customBackground: PaletteOptions["primary"];
  }
}
export const darkTheme: ThemeOptions = {
  palette: {
    mode: "dark",
    evidenceColor: {
      main: "#39763a",
      contrastText: "red",
    },
    bwColor: {
      main: "#fff",
      light: "#000",
      contrastText: "#ccc",
    },
    customBackground: {
      main: "#e3e3e3", // main Background Color
      light: "#f7f7f7",
    },
  },
  typography: {
    fontFamily: ["Quicksand", "Serif"].join(","),
  },
};
