import instance from "./interceptor";

export const STATISTICHE_MARINA_API = {
  getDashboard: async (year: string) => {
    const urlPath = year ? `stats/dashboard?year=${year}` : "stats/dashboard";
    const result = await instance.get(urlPath);
    if (result.data.status === "FOUND") {
      return result.data;
    }
  },
  getDashboardMenu: async () => {
    const result = await instance.get("service/additionalMenu?menu=dashboard");
    if (result.data.status === "FOUND") {
      return result.data;
    }
  },
  getStatistiche: async (year: string) => {
    const urlPath = year
      ? `/stats/dashboard/occupancy?year=${year}`
      : "/stats/dashboard/occupancy";
    const result = await instance.get(urlPath);
    if (result.data.status === "FOUND") {
      return result.data;
    }
  },
  getRicavi: async (year: string) => {
    const urlPath = year
      ? `/stats/dashboard/income?year=${year}`
      : "/stats/dashboard/income";
    const result = await instance.get(urlPath);
    if (result.data.status === "FOUND") {
      return result.data;
    }
  },
  getFatture: async (year: string) => {
    const urlPath = year
      ? `/stats/dashboard/invoiced?year=${year}`
      : "/stats/dashboard/invoiced";
    const result = await instance.get(urlPath);
    if (result.data.status === "FOUND") {
      return result.data;
    }
  },
};
