import { useEffect, useState, ReactElement, FC } from "react";
import {
  Avatar,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { red } from "@mui/material/colors";
import { GridFilterModel } from "@mui/x-data-grid-premium";
import { blueGrey } from "@mui/material/colors";
interface NameObject {
  field?: string;
  operator?: string;
  value?: string;
  id?: number;
  fromInput?: string;
  headerLetter?: string;
}

const FilterBySelectComponent: FC<any> = (props): ReactElement => {
  const [value, setValue] = useState<string>("");
  const [filter, setFilter] = useState<GridFilterModel>(props.filterModel);

  const selectFilterParams = props.params;
  // TODO: to be optained by specific Endpoint
  const options = props.options;

  useEffect(() => {
    if (props.filterModel.items.length > 0) {
      props.filterModel.items.map((item: NameObject) => {
        if (item.field === selectFilterParams.fieldName && item.value) {
          setValue(item.value);
        }
        return null; // Add a return statement here
      });
    } else {
      setValue("");
    }
    setFilter(props.filterModel);
    return;
  }, [props, selectFilterParams.fieldName]);

  const handleSelectChange = (evt: SelectChangeEvent) => {
    setValue(evt.target.value);
    const stringToSearch = filter.items.filter(
      (item) => item.field === selectFilterParams.fieldName
    );

    if (stringToSearch.length > 0 && evt.target.value.length > 0) {
      stringToSearch[0].value = evt.target.value;
    } else if (stringToSearch.length > 0 && evt.target.value.length === 0) {
      filter.items = filter.items.filter(
        (item) => item.field !== selectFilterParams.fieldName
      );
    } else {
      filter.items.push({
        field: selectFilterParams.fieldName,
        operator: selectFilterParams.operator,
        id: `${selectFilterParams.operator}_${selectFilterParams.fieldName}`,
        value: evt.target.value,
      });
    }
    setFilter(filter);
    props.onFilterChange(filter);
  };
  return (
    <CardContent sx={{ width: "100%" }}>
      <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
        {selectFilterParams.title}
      </Typography>
      <FormControl sx={{ m: 0, width: "100%" }} size="small">
        <InputLabel
          id={`${selectFilterParams.id}-label`}
          size="small"
          color="primary"
          sx={{ color: blueGrey[500] }}
        >
          {selectFilterParams.label}
        </InputLabel>
        <Select
          labelId={`${selectFilterParams.id}-label`}
          id={selectFilterParams.id}
          value={value}
          label={selectFilterParams.label}
          onChange={handleSelectChange}
          sx={{ minWidth: "20em" }}
          inputProps={{
            style: {
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              width: "100%",
              color: blueGrey[700],
            },
          }}
        >
          <MenuItem value="">
            <em>{selectFilterParams.emptyOption}</em>
          </MenuItem>
          {options.map(
            (item: { type: string; label: string }, index: number) => {
              return (
                <MenuItem key={index} value={item.type}>
                  {item.label}
                </MenuItem>
              );
            }
          )}
        </Select>
      </FormControl>
    </CardContent>
  );
};

export default FilterBySelectComponent;
