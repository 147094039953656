import instance from "./interceptor";

export const DATAGRID_API = {
  getConfigData: async (params?: string) => {
    const urlPath = `service/datagrid/${params}`;
    const result = await instance.get(urlPath);
    if (result.data.status === "FOUND") {
      return result.data;
    }
  },
  updateConfigData: async (params: string, data: {}) => {
    const urlPath = `service/datagrid/${params}`;
    const result = await instance.put(urlPath, data);
    if (result.data.status === "FOUND") {
      return result.data;
    }
  },
  getData: async (params?: string) => {
    const urlPath = `/${params}`;
    const result = await instance.get(urlPath);
    if (result.data.status === "FOUND") {
      return result.data;
    }
  },
  getTotals: async (params?: string) => {
      // const urlPath = `/${params}`;
      // const result = await instance.get(urlPath);
      // if (result.data.status === "FOUND") {
      //   return result.data;
    // }
    console.log("getTotals CALLED", params);
    },
}