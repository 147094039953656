// @ts-nocheck

import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { Button } from "@mui/material";
import {
  AppBar,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Link,
  MenuItem,
  Popover,
  Typography,
  Toolbar,
} from "@mui/material";

import {
  AccountCircle,
  Apps as AppsIcon,
  Badge,
  Mail as MailIcon,
  Notifications as NotificationsIcon,
} from "@mui/icons-material";

import DinamicIcon from "./DinamicIcon";
import { SwitchModeButton } from "../SwitchModeButton";
import bs_white_no_payoff from "../../assets/img/logo-blueshell-bianco-orizzontale-no-payoff.png";
import { styled } from "@mui/material/styles";
import ProfileMenu from "../header-component/ProfileMenu";
import AppsMenu from "../header-component/AppsMenu";
import { CONFIGS } from "../../configurations";
import { NAVIGATION_BAR_API } from "../../api/navigation_bar";
import { NavigationMenu } from "../../interfaces";

const DinamicNavbar = ({ userData }: any) => {
  const [name, SetName] = useState("");
  const profileMenuId = "profile-menu";
  const appsMenuId = "app-menu";

  const [profileAnchorEl, setProfileAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [appsAnchorEl, setAppAnchorEl] = useState<null | HTMLElement>(null);
  const isProfileMenuOpen = Boolean(profileAnchorEl);
  const isAppsMenuOpen = Boolean(appsAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleAppsMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAppAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setProfileAnchorEl(null);
    setAppAnchorEl(null);
  };

  const handleChangeLanguage = (e) => {
    const language = e.target.dataset.lan;
    window.sessionStorage.setItem("language", language);
    handleMenuClose();
    return window.location.reload(true);
  };

  useEffect(() => {
    userData && SetName(userData.name);
  }, [userData]);

  const [navigationMenu, SetNavigationMenu] = useState<NavigationMenu>({});

  useEffect(() => {
    NAVIGATION_BAR_API.getNavigationMenu()
      .then((resp) => {
        SetNavigationMenu(resp.data);
      })
      .catch((err) => {
        console.log("NAVMENU ERROR", err);
      })
      .finally(() => console.log("NAVMENU LOADED"));
  }, []);

  const Div = styled("div")(({ theme }) => ({
    ...theme.typography.button,
    color: theme.palette.text.primary,
    backgroundColor: "transparent",
    lineHeight: 0,
    display: "inline-flex",
    padding: "0 1rem 0 0",
  }));
  const [loadingContent, SetLoadingContent] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [idEl, setIdEl] = useState(null);
  const open = idEl;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIdEl(event.currentTarget.id);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(null);
    setIdEl(null);
  };

  const checkLoadingAndGo = (url: string) => {
    setAnchorEl(null);
    setIdEl(null);
    // Temporary "loading" is opened when the Url is to the "Old site"
    url && url.includes("https://") && SetLoadingContent(!loadingContent);
  };

  const NavigationLink = (props) => {
    const { label, href } = props;

    return (
      <Link
        component={NavLink}
        to={href}
        sx={{
          color: "inherit",
          ":hover": {
            color: "text.primary",
          },
        }}
        underline="none"
        onClick={() => {
          checkLoadingAndGo(href);
        }}
      >
        <MenuItem sx={{ color: "inherit" }}>
          <DinamicIcon icon="NavigateNextTwoTone" />
          <Typography
            textAlign="center"
            sx={{
              textTransform: "Capitalize",
              fontSize: "0.875rem",
            }}
          >
            {label}&nbsp;
          </Typography>
        </MenuItem>
      </Link>
    );
  };
  return (
    <Box sx={{ marginBottom: 8 }}>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme: any) => theme.zIndex.drawer + 10000,
        }}
        open={loadingContent}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AppBar sx={{ zIndex: "10000" }}>
        <Toolbar>
          <Box style={{ display: "flex" }}>
            <Div>
              <img src={bs_white_no_payoff} width="140" alt="BlueShell V5" />
            </Div>
            {navigationMenu.length > 0 &&
              navigationMenu.map((menuItem, idx) => (
                <React.Fragment key={`idx_main_${menuItem.label}-${idx}`}>
                  {menuItem.elements.length > 0 ? (
                    <Box>
                      <Button
                        id={`basic-button_${menuItem.label}-${idx}`}
                        aria-controls={
                          open === `basic-button_${menuItem.label}-${idx}`
                            ? "basic-menu"
                            : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={
                          open === `basic-button_${menuItem.label}-${idx}`
                            ? "true"
                            : undefined
                        }
                        onClick={handleClick}
                        sx={{
                          textTransform: "Capitalize",
                          color:
                            open === `basic-button_${menuItem.label}-${idx}`
                              ? "white !important"
                              : "inherit",
                          ":hover": {
                            color: "text.secondary",
                          },
                        }}
                      >
                        <DinamicIcon icon={menuItem.icon} />
                        {menuItem.label}
                      </Button>
                      <Popover
                        id={
                          open === `basic-button_${menuItem.label}-${idx}`
                            ? "simple-popover"
                            : undefined
                        }
                        open={
                          open === `basic-button_${menuItem.label}-${idx}` &&
                          true
                        }
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        sx={{
                          top: ".9rem",
                          left: "-4rem",
                          "& div": {
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            minWidth: "15rem",
                          },
                        }}
                      >
                        {menuItem.elements.map((element, idx) => (
                          <React.Fragment
                            key={`idx_ele_${element.title}-${idx}`}
                          >
                            <Typography
                              sx={{
                                backgroundColor: "primary.main",
                                color: "white",
                                fontWeight: "bold",
                                padding: "0.25rem 1rem",
                              }}
                            >
                              {element.title}
                            </Typography>
                            {element.links.map((link, idx) => (
                              <NavigationLink {...link} key={idx} />
                            ))}
                          </React.Fragment>
                        ))}
                      </Popover>
                    </Box>
                  ) : (
                    <Link
                      component={NavLink}
                      to={menuItem.href}
                      sx={{
                        color: "inherit",
                        ":hover": {
                          color: "text.secondary",
                        },
                      }}
                      underline="none"
                      variant="button"
                    >
                      <MenuItem sx={{ color: "inherit" }}>
                        <DinamicIcon icon={menuItem.icon} />
                        <Typography
                          textAlign="center"
                          sx={{
                            textTransform: "Capitalize",
                            fontSize: "0.875rem",
                          }}
                        >
                          {menuItem.label}&nbsp;
                        </Typography>
                      </MenuItem>
                    </Link>
                  )}
                </React.Fragment>
              ))}
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {CONFIGS.showMessagesMenu && (
              <IconButton
                size="large"
                aria-label="show 4 new mails"
                color="inherit"
              >
                <Badge badgeContent={4} color="error">
                  <MailIcon
                    sx={{
                      ":hover": {
                        color: "text.secondary",
                      },
                    }}
                  />
                </Badge>
              </IconButton>
            )}
            {CONFIGS.showNotificationsMenu && (
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <Badge badgeContent={17} color="error">
                  <NotificationsIcon
                    sx={{
                      ":hover": {
                        color: "text.secondary",
                      },
                    }}
                  />
                </Badge>
              </IconButton>
            )}
            {CONFIGS.showProfileMenu && (
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={profileMenuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle
                  sx={{
                    ":hover": {
                      color: "text.secondary",
                    },
                  }}
                />
                <Typography
                  textAlign="left"
                  variant="subtitle2"
                  sx={{
                    textTransform: "Capitalize",
                  }}
                >
                  {name}
                </Typography>
              </IconButton>
            )}
            {CONFIGS.showAppsMenu && (
              <IconButton
                size="large"
                edge="end"
                aria-label="Platform apps"
                aria-controls={profileMenuId}
                aria-haspopup="true"
                onClick={handleAppsMenuOpen}
                color="inherit"
              >
                <AppsIcon
                  sx={{
                    ":hover": {
                      color: "text.secondary",
                    },
                  }}
                />
              </IconButton>
            )}
          </Box>
          {CONFIGS.showDarkMode && (
            <Box sx={{ display: { xs: "none", md: "flex" }, marginLeft: 2 }}>
              <SwitchModeButton />
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <ProfileMenu
        profileAnchorEl={profileAnchorEl}
        profileMenuId={profileMenuId}
        isProfileMenuOpen={isProfileMenuOpen}
        handleChangeLanguage={handleChangeLanguage}
        handleMenuClose={handleMenuClose}
      />
      <AppsMenu
        appsAnchorEl={appsAnchorEl}
        appsMenuId={appsMenuId}
        isAppsMenuOpen={isAppsMenuOpen}
        handleMenuClose={handleMenuClose}
      />
    </Box>
  );
};

export default DinamicNavbar;
