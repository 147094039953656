// src/pages/CommonDataGrid.tsx

import React, { ReactElement, FC } from "react";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";

import DinamicDataGrid from "./DinamicDataGrid";

const CommonDataGrid: FC<any> = (): ReactElement => {
  const { gridId } = useParams();
  return (
    <>
      <Typography
        variant="h5"
        sx={{ textTransform: "capitalize", padding: "1em 1em 0 1em" }}
      >
        <b>{gridId}</b>
      </Typography>
      <DinamicDataGrid gridName={gridId} />
    </>
  );
};

export default CommonDataGrid;
