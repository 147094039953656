// src/pages/DinamicDataGrid.tsx

import {
  ReactElement,
  FC,
  useCallback,
  useRef,
  useEffect,
  useMemo,
  useState,
  Fragment,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Backdrop,
  Box,
  Button,
  Card,
  Fade,
  Grid,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import {
  GridColumnVisibilityModel,
  GridColumnOrderChangeParams,
  GridSortModel,
  GridFilterModel,
  GridPaginationModel,
  GridRowId,
  GridToolbar,
  useGridApiRef,
} from "@mui/x-data-grid-premium";

import {
  Delete as DeleteIcon,
  FilterAlt as FilterAltIcon,
  FilterAltOff as FilterAltOffIcon,
} from "@mui/icons-material";

import StripedGrid from "./datagridComponents/StripedGrid";

import { PAGEDATA_API } from "../api/pageData";
import { CONFIGS } from "../configurations";
import { DATAGRID_API } from "../api/datagrid";
import { HELPERS } from "../utilities/helpers";

import DeleteUserActionItem from "../components/dinamicDataGrid/DeleteUserActionItem";
import { Filtercomponents } from "../components/filtercomponents/Filtercomponents";

import { teal } from "@mui/material/colors";
import Chipscomponent from "../components/filtercomponents/Chipscomponents";

import { mockedTotalObj } from "./mockedTotal";

const columnsActions = {
  field: "actions",
  type: "actions",
  width: 80,
  getActions: (params: any) => {
    return params.row.type !== "totals"
      ? [
          <DeleteUserActionItem
            icon={<DeleteIcon />}
            label="Delete User"
            params={params}
          />,
        ]
      : [
          <Typography variant="button" sx={{ fontWeight: "bold" }}>
            Totals
          </Typography>,
        ];
  },
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 2,
  p: 2,
};

const DinamicDataGrid: FC<any> = (props): ReactElement => {
  const navigate = useNavigate();
  let location = useLocation();
  const apiRef = useGridApiRef();

  const TYPEOFGRID = props.gridName;
  const mapPageToNextCursor = useRef<{ [page: number]: GridRowId }>({});
  const [paginationModel, SetPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });
  const [totalsRow, SetTotalsRow] = useState<object | undefined>();
  const [columnsModel, SetColumnsModel] = useState<any>({
    columns: [],
    initialState: {
      columns: { columnVisibilityModel: {} },
    },
  });
  const [columnVisibilityModel, SetColumnVisibilityModel] = useState<any>({});
  const [customFilterOptions, SetcustomFilterOptions] =
    useState<GridFilterModel>({
      items: [],
    });
  const [filterOptionsPath, SetFilterOptionsPath] = useState("");
  const [pageInfo, SetPageInfo] = useState({
    totalRowCount: 0,
    nextCursor: "",
    pagesize: 2,
  });

  const [isLoading, SetIsLoading] = useState(true);
  const [svrRows, SetSvrRows] = useState([]);
  const [gridDataId, SetGridDataId] = useState("");
  const [sorting, SetSorting] = useState<any>({ sorting: [] });
  const [filterApplied, SetFilterApplied] = useState<boolean>(false);

  const queryOptions = useMemo(() => {
    return {
      cursor: mapPageToNextCursor.current[paginationModel.page - 1],
      pageSize: paginationModel.pageSize,
    };
  }, [paginationModel]);

  const [rowCountState, SetRowCountState] = useState(
    pageInfo?.totalRowCount || 0
  );
  const [useFilters, SetUseFilters] = useState(false);

  const [filtersParams, SetFiltersParams] = useState<{
    filtersPanelLabel: {
      mainLabel: string;
      resetBtn: string;
      applyBtn: string;
    };
    data: {
      fieldName?: string;
      type?: string;
      operator?: string;
      title?: string;
      resetButtonLabel?: string;
      headerLetter?: string;
      label?: string;
      emptyOption?: string;
      id?: string;
      options?: { type: string; label: string }[];
    }[][];
  }>({
    filtersPanelLabel: { mainLabel: "", resetBtn: "", applyBtn: "" },
    data: [[]],
  });

  useEffect(() => {
    let ApiResp: { data: { page: { grid?: { gridId?: string }[] } } } = {
      data: { page: {} },
    };
    let gridIdReceived = "";

    PAGEDATA_API.getPageData(location.pathname)
      .then((resp) => {
        ApiResp = resp;
        document.title = `${resp.data.page.title} - ${CONFIGS.webApptitle}`;
        gridIdReceived = resp.data.page.grid[0].gridId;
        if (
          ApiResp &&
          ApiResp.data &&
          ApiResp.data.page &&
          ApiResp.data.page.grid &&
          ApiResp.data.page.grid[0] &&
          ApiResp.data.page.grid[0].gridId &&
          ApiResp.data.page.grid.length > 0
        ) {
          SetGridDataId(ApiResp.data.page.grid[0].gridId);
        }
      })
      .finally(() => {
        DATAGRID_API.getConfigData(gridIdReceived)
          .then((resp) => {
            if (resp.status === "FOUND") {
              const columnsArr = resp.data.configGrid.columns;
              columnsArr.splice(0, 0, columnsActions);
              columnsArr.map(
                (row: { field: string; type: string; valueGetter: any }) => {
                  const type =
                    row.type && row.type !== "actions" ? row.type : undefined;
                  if (type) {
                    switch (type) {
                      case "date":
                      case "dateTime":
                        row.valueGetter = HELPERS.formatDate;
                        break;
                      case "currency":
                        row.valueGetter = HELPERS.formatCurrency;
                        break;
                      default:
                        console.log(
                          `Error Type "${row.type}" is NOT present in the helper functions.`
                        );
                    }
                  }
                  return row;
                }
              );
              // Start Verifico se c'è e Attivo il pannello Filtri
              if (
                resp.data.configFilter &&
                resp.data.configFilter.data &&
                resp.data.configFilter.data.length
              ) {
                SetUseFilters(true);
                SetFiltersParams(resp.data.configFilter);
              }
              // End Verifico se c'è e Attivo il pannello Filtri
              SetColumnsModel(resp.data.configGrid);
              resp.data.configGrid.customFilter &&
                resp.data.configGrid.customFilter.items.length > 0 &&
                SetcustomFilterOptions(resp.data.configGrid.customFilter);
              SetColumnVisibilityModel(
                resp.data.configGrid.initialState.columns.columnVisibilityModel
              );
              resp.data.configGrid.sortModel &&
                resp.data.configGrid.sortModel.length > 0 &&
                SetSorting({ sorting: resp.data.configGrid.sortModel });
            }
          })
          .catch((err) => {
            console.log("Initial columns Api response error...", err);
          });
      })
      .catch((err) => {
        console.log("Initial PageData Api response error...", err);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    useFilters
      ? console.log("Grid Filtrabile")
      : console.log("Grid NON Filtrabile");
  }, [useFilters]);

  const fetchData = useCallback(
    (pathForApi: string, filterOptionsPath?: string, gridDataId?: string) => {
      SetIsLoading(true);
      SetFilterApplied(false);
      DATAGRID_API.getData(pathForApi)
        .then((resp) => {
          if (resp.status === "FOUND") {
            SetSvrRows(resp.data);
            const newPageInfo = {
              totalRowCount: resp.extras.filtered_rows,
              nextCursor: `?page=${
                parseInt(resp.input.page) + 1
              }&pageSize=${parseInt(resp.input.pageSize)}`,
              pagesize: parseInt(resp.input.pageSize),
              page: parseInt(resp.input.page),
            };
            SetPageInfo(newPageInfo);
            SetIsLoading(false);
            apiRef.current.updateRows(resp.data);
          }
        })
        .then(() => {
          apiRef.current.autosizeColumns({
            includeHeaders: true,
            includeOutliers: true,
            outliersFactor: 1.5,
            expand: true,
          });
        })
        .finally(() => {
          // TODO: Implement the getTotals API
          const filters =
            filterOptionsPath !== "" ? `?${filterOptionsPath}` : "";
          const totalsPath = `api/${gridDataId}${filters}`;
          DATAGRID_API.getTotals(totalsPath).then((resp) => {
            setTimeout(() => {
              SetTotalsRow(mockedTotalObj.data[0]);
            }, 1500);
          });
        })
        .catch((err) => {
          SetIsLoading(false);
          console.log("Companies Api response error...", err);
        });
    },
    // eslint-disable-next-line
    [apiRef]
  );

  useEffect(() => {
    SetIsLoading(true);
    let pathForApi =
      queryOptions && queryOptions.cursor
        ? `api/${gridDataId}${queryOptions.cursor.toString()}`
        : `api/${gridDataId}?page=0&pageSize=${paginationModel.pageSize}`;
    if (Object.keys(sorting).length > 0) {
      pathForApi = `${pathForApi}&sort=${JSON.stringify(sorting.sorting)}`;
    }
    if (Object.keys(customFilterOptions.items).length > 0) {
      // Created here the const with filters string params
      const filterOptionsPathToSave = `filter=${JSON.stringify(
        customFilterOptions
      )}`;
      SetFilterOptionsPath(filterOptionsPathToSave);
      if (queryOptions && queryOptions.cursor) {
        pathForApi = `api/${gridDataId}${queryOptions.cursor.toString()}&${filterOptionsPathToSave}`;
      } else {
        pathForApi = `api/${gridDataId}?page=0&pageSize=${paginationModel.pageSize}&${filterOptionsPathToSave}`;
      }
      updatePath();
    } else {
      SetFilterOptionsPath("");
      updatePath();
    }

    if (gridDataId !== "") {
      fetchData(pathForApi, filterOptionsPath, gridDataId);
    }
    // filterOptions removed from dependencies, because it's a state that is not used in the fetchData function
    // eslint-disable-next-line
  }, [queryOptions, gridDataId, sorting, paginationModel, filterApplied]);

  useEffect(() => {
    if (!isLoading && pageInfo?.nextCursor) {
      // We add nextCursor when available
      mapPageToNextCursor.current[paginationModel.page] = pageInfo?.nextCursor;
    }
  }, [paginationModel.page, isLoading, pageInfo?.nextCursor]);

  useEffect(() => {
    if (!isLoading && pageInfo?.nextCursor) {
      // We add nextCursor when available
      mapPageToNextCursor.current[paginationModel.page] = pageInfo?.nextCursor;
    }
  }, [paginationModel.page, isLoading, pageInfo?.nextCursor]);

  useEffect(() => {
    SetRowCountState((prevRowCountState) =>
      pageInfo?.totalRowCount !== undefined
        ? pageInfo?.totalRowCount
        : prevRowCountState
    );
  }, [pageInfo?.totalRowCount, SetRowCountState]);

  const updatePath = () => {
    if (filterOptionsPath !== "") {
      navigate(`${location.pathname}?${filterOptionsPath}`, {
        replace: false,
      });
    } else {
      navigate(location.pathname, {
        replace: false,
      });
    }
  };
  const updateColumnsVisibility = (newModel: GridColumnVisibilityModel) => {
    columnsModel.initialState.columns.columnVisibilityModel = newModel;
    SetColumnVisibilityModel(newModel);
    SetColumnsModel(columnsModel);
    const newColumnVisibilityModel = {
      columnVisibilityModel: newModel,
    };
    DATAGRID_API.updateConfigData(TYPEOFGRID, newColumnVisibilityModel);
    apiRef.current.autosizeColumns({
      includeHeaders: true,
      includeOutliers: true,
      outliersFactor: 1.5,
      expand: true,
    });
  };

  const updateColumnsOrder = (columnsOrder: GridColumnOrderChangeParams) => {
    const objToMove = columnsModel.columns.splice(columnsOrder.oldIndex, 1)[0];
    columnsModel.columns.splice(columnsOrder.targetIndex, 0, objToMove);
    const newColumnsOrder = { columns: columnsModel.columns };
    DATAGRID_API.updateConfigData(TYPEOFGRID, newColumnsOrder);
  };

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      // Here you save the data you need from the sort model
      sortModel.length > 0 && SetSorting({ sorting: [...sortModel] });
    },
    // eslint-disable-next-line
    []
  );
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    // We have the cursor, we can allow the page transition.
    if (
      newPaginationModel.page === 0 ||
      mapPageToNextCursor.current[newPaginationModel.page - 1]
    ) {
      SetPaginationModel(newPaginationModel);
    }
  };

  const onFilterChange = useCallback((newFilterModel: GridFilterModel) => {
    SetTotalsRow(undefined);
    if (newFilterModel.items.length > 0) {
      SetcustomFilterOptions({ ...newFilterModel });
      SetFilterOptionsPath(`filter=${JSON.stringify(newFilterModel)}`);
      DATAGRID_API.updateConfigData(TYPEOFGRID, { filter: newFilterModel });
    } else {
      SetcustomFilterOptions({ items: [] });
      SetFilterOptionsPath("");
      updatePath();
      DATAGRID_API.updateConfigData(TYPEOFGRID, { filter: { items: [] } });
    }
    updatePath();
    // eslint-disable-next-line
  }, []);

  const getFilteredData = () => {
    SetFilterApplied(true);
    handleClose();
  };
  const clearFilters = () => {
    const emptyFilter = {
      items: [],
    };
    SetcustomFilterOptions(emptyFilter);
    onFilterChange(emptyFilter);
    SetFilterApplied(true);
    handleClose();
    SetFilterOptionsPath("");
    updatePath();
  };

  const [open, SetOpen] = useState(false);
  const handleOpen = () => SetOpen(true);
  const handleClose = (event?: any, reason?: any) => {
    if (reason !== "backdropClick") {
      SetOpen(false);
    }
  };
  const Break = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#ccc",
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: "100%",
    height: "1px",
    boxShadow: "0px 0px 0px 0px",
    marginLeft: "1em",
  }));

  return (
    <>
      {useFilters && (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{
            width: "95vw",
            marginLeft: 5.5,
            backgroundColor: teal[100],
            padding: 1,
          }}
        >
          <Button
            sx={{
              width: "18em",
              backgroundColor:
                customFilterOptions.items.length > 0 ? teal[700] : teal[100],
              border: `1px solid ${teal[200]}`,
              marginTop: 0.5,
              marginLeft: 5,
              textAlign: "center",
              borderRadius: 2,
            }}
            color={
              customFilterOptions.items.length > 0 ? "secondary" : "primary"
            }
            startIcon={
              customFilterOptions.items.length > 0 ? (
                <FilterAltIcon />
              ) : (
                <FilterAltOffIcon />
              )
            }
            onClick={handleOpen}
          >
            {customFilterOptions.items.length > 0
              ? "Filtri applicati"
              : "Filtra i risutalti"}
          </Button>
          <Chipscomponent
            {...customFilterOptions}
            clearFilters={clearFilters}
          />
        </Stack>
      )}
      <Modal
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box>
              <Typography
                variant="h5"
                sx={{ background: teal[200], paddingLeft: 2 }}
              >
                {filtersParams.filtersPanelLabel.mainLabel}
              </Typography>
              <Grid container spacing={2} justifyContent={"left"}>
                {filtersParams.data.length > 0 &&
                  filtersParams.data.map((filtersGroup, index) => {
                    return (
                      <Fragment key={index}>
                        {filtersGroup.map((filter, index) => {
                          return (
                            <Grid
                              key={`${filter.id}-${index}`}
                              item
                              xs={filter.type === "inputText" && 4}
                              sx={{ display: "flex" }}
                            >
                              <Filtercomponents
                                params={filter}
                                onFilterChange={onFilterChange}
                                customFilterOptions={customFilterOptions}
                              />
                            </Grid>
                          );
                        })}
                        <Break />
                      </Fragment>
                    );
                  })}
              </Grid>
            </Box>
            <Card
              sx={{
                padding: 0.5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "0.5em auto",
              }}
              variant="outlined"
            >
              <Button
                sx={{ marginTop: 0.5, marginBottom: 0.5 }}
                variant="outlined"
                startIcon={<DeleteIcon />}
                onClick={clearFilters}
              >
                {filtersParams.filtersPanelLabel.resetBtn}
              </Button>
              <Button
                sx={{ marginTop: 0.5, marginBottom: 0.5, marginLeft: 3 }}
                variant="outlined"
                startIcon={<FilterAltIcon />}
                onClick={getFilteredData}
              >
                {filtersParams.filtersPanelLabel.applyBtn}
              </Button>
            </Card>
          </Box>
        </Fade>
      </Modal>
      <Box
        sx={{
          height: "70vh",
          width: "95vw",
          backgroundColor: teal[200],
          margin: "1.5em auto 0.5em",
        }}
      >
        <StripedGrid
          apiRef={apiRef}
          density="compact"
          rows={svrRows}
          columns={columnsModel.columns}
          columnVisibilityModel={columnVisibilityModel}
          rowCount={rowCountState}
          loading={isLoading}
          slots={{
            toolbar: GridToolbar,
          }}
          pageSizeOptions={[5, 25, 50, 100, { value: 500000, label: "All" }]}
          paginationModel={paginationModel}
          paginationMode="server"
          filterMode="server"
          onPaginationModelChange={handlePaginationModelChange}
          onColumnVisibilityModelChange={(newModel: {}) => {
            updateColumnsVisibility(newModel);
          }}
          onColumnOrderChange={(columnsOrder: any) =>
            updateColumnsOrder(columnsOrder)
          }
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          pagination
          disableColumnFilter
          // autoHeight
          pinnedRows={totalsRow && { bottom: [totalsRow] }}
        />
      </Box>
    </>
  );
};

export default DinamicDataGrid;
