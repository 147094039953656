// src/pages/Home.tsx

import React, { ReactElement, FC, useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

import RicaviCard from "../../components/RicaviCard";

import { RicaviCardProps, RicaviSection, SubType } from "../../interfaces";

import { STATISTICHE_MARINA_API } from "../../api/statistiche_marina";

const RicaviSectionComponent: FC<any> = (props): ReactElement => {
  const [loadingContent, SetLoadingContent] = useState(true);
  const [ricaviSection, SetRicaviSection] = useState<RicaviSection>({});
  const [geolocRicaviSection, SetGeolocRicaviSection] = useState<SubType>(
    ricaviSection.subType || {}
  );

  const year = props.year || "";

  useEffect(() => {
    STATISTICHE_MARINA_API.getRicavi(year)
      .then((resp) => {
        resp.status === "FOUND" && SetRicaviSection(resp.data);
        resp.status === "FOUND" && SetGeolocRicaviSection(resp.data.subtype);
      })
      .catch((err) => {
        console.log("RICAVI ERROR", err);
      })
      .finally(() => SetLoadingContent(false));
  }, [year]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "secondary.main",
        margin: 1,
        padding: 2,
      }}
    >
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme: any) => theme.zIndex.drawer + 1,
        }}
        open={loadingContent}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Typography variant="h6" sx={{ marginTop: 2, fontWeight: 600 }}>
        {ricaviSection.dataTypeLabel}
      </Typography>
      <Divider component="div" role="presentation" sx={{ marginBottom: 1 }} />
      {Object.keys(ricaviSection).length > 0 ? (
        <Grid container spacing={1}>
          {ricaviSection.cards &&
            ricaviSection.cards.map((item: RicaviCardProps, index: number) => (
              <Grid item xs={6} key={index}>
                <RicaviCard title={item.title} mainValue={item.mainValue} />
              </Grid>
            ))}
        </Grid>
      ) : (
        "No data to show"
      )}
      {Object.keys(geolocRicaviSection).length > 0 ? (
        <Grid container spacing={1} sx={{ marginTop: 0.5 }}>
          {geolocRicaviSection.cards &&
            geolocRicaviSection.cards.map(
              (item: RicaviCardProps, index: number) => (
                <Grid item xs={4} key={index}>
                  <RicaviCard title={item.title} mainValue={item.mainValue} />
                </Grid>
              )
            )}
        </Grid>
      ) : (
        "No data to show"
      )}
    </Box>
  );
};

export default RicaviSectionComponent;
