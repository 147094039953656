import { useEffect, useState, ReactElement, FC } from "react";
import { CardContent, Typography } from "@mui/material";

import { GridFilterModel } from "@mui/x-data-grid-premium";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { DateRange } from "@mui/x-date-pickers-pro/models";
import { itIT } from "@mui/x-date-pickers/locales";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";

import "dayjs/locale/it";
import { blueGrey } from "@mui/material/colors";

const DateRangePickerComponent: FC<any> = (props): ReactElement => {
  const [value, setValue] = useState<DateRange<Dayjs>>([null, null]);
  const [filter, setFilter] = useState<GridFilterModel>(props.filterModel);
  const { fieldName, title, operatorAfter, operatorBefore } = props.params;

  useEffect(() => {
    const dateRange = filter.items.filter((item) => item.field === fieldName);
    if (dateRange.length > 1) {
      setFilter(props.filterModel);
      const datesArray: any = [];
      props.filterModel.items.map(
        (dateVal: { field?: string; value?: string }) => {
          if (dateVal.field === fieldName) {
            const date = dayjs(dateVal.value);
            datesArray.push(date);
          }
          return null;
        }
      );
      setValue(datesArray);
    } else {
      setValue([null, null]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const handleDateChange = (value: any) => {
    const dateBeforeThen = filter.items.filter(
      (item) => item.operator === operatorBefore
    );
    const dateAfterThen = filter.items.filter(
      (item) => item.operator === operatorAfter
    );
    if (dateAfterThen.length === 0 && value[0] !== null) {
      filter.items.push({
        field: fieldName,
        operator: operatorAfter,
        id: operatorAfter,
        value: value[0].$d.toISOString().substring(0, 16),
      });
    } else if (dateAfterThen.length > 0 && value[0] !== null) {
      filter.items[0].value = value[0].$d.toISOString().substring(0, 16);
    }
    if (dateBeforeThen.length === 0 && value[1] !== null) {
      filter.items.push({
        field: fieldName,
        operator: operatorBefore,
        id: operatorBefore,
        value: value[1].$d.toISOString().substring(0, 16),
      });
    } else if (dateBeforeThen.length > 0 && value[1] !== null) {
      filter.items[1].value = value[1].$d.toISOString().substring(0, 16);
    }
    setFilter(filter);
  };

  const closeDatePicker = () => {
    props.onFilterChange(filter);
  };

  return (
    <CardContent>
      <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
        {title}
      </Typography>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        localeText={
          itIT.components.MuiLocalizationProvider.defaultProps.localeText
        }
        adapterLocale="it"
      >
        <DateRangePicker
          sx={{
            width: "100%",
            "& .MuiInputLabel-root": { color: blueGrey[700] }, //styles the label
          }}
          onChange={handleDateChange}
          onClose={closeDatePicker}
          value={value}
          format="DD-MMMM"
          slotProps={{
            field: { dateSeparator: "/" },
            textField: { color: "primary", size: "small" },
          }}
        />
      </LocalizationProvider>
    </CardContent>
  );
};

export default DateRangePickerComponent;
