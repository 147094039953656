import instance from "./interceptor";

export const NAVIGATION_BAR_API = {
  getNavigationMenu: async () => {
    const result = await instance.get("/service/menu");
    if (result.data.status === "FOUND") {
      return result.data;
    }
  },
};
