import { Grid, Menu, MenuItem, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  AdminPanelSettingsTwoTone as AdminPanelSettingsTwoToneIcon,
  ConfirmationNumberTwoTone as ConfirmationNumberTwoToneIcon,
  AutoStoriesTwoTone as AutoStoriesTwoToneIcon,
  BrightnessAutoTwoTone as BrightnessAutoTwoToneIcon,
  ForumTwoTone as ForumTwoToneIcon,
} from "@mui/icons-material";

interface appsMenuProps {
  appsAnchorEl?: null | HTMLElement;
  appsMenuId?: string;
  isAppsMenuOpen: boolean;
  handleMenuClose: () => void;
}

const AppMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: "0.6em",
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
}));

export default function AppsMenu({
  appsAnchorEl,
  appsMenuId,
  isAppsMenuOpen,
  handleMenuClose,
}: appsMenuProps) {
  return (
    <Menu
      id={appsMenuId}
      keepMounted
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          p: 1,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      anchorEl={appsAnchorEl}
      open={isAppsMenuOpen}
      onClose={handleMenuClose}
    >
      <Grid container>
        <Grid item xs={4}>
          <AppMenuItem onClick={handleMenuClose}>
            <AdminPanelSettingsTwoToneIcon
              onClick={() => {
                console.log("click 1");
              }}
              sx={{ fontSize: 36, display: "block" }}
            />
            <Typography variant="caption">App 1</Typography>
          </AppMenuItem>
        </Grid>
        <Grid item xs={4}>
          <AppMenuItem onClick={handleMenuClose}>
            <ConfirmationNumberTwoToneIcon
              onClick={() => {
                console.log("click 2");
              }}
              sx={{ fontSize: 36 }}
            />
            <Typography variant="caption">App 2</Typography>
          </AppMenuItem>
        </Grid>
        <Grid item xs={4}>
          <AppMenuItem onClick={handleMenuClose}>
            <AutoStoriesTwoToneIcon
              onClick={() => {
                console.log("click 3");
              }}
              sx={{ fontSize: 36 }}
            />
            <Typography variant="caption">App 3</Typography>
          </AppMenuItem>
        </Grid>
        <Grid item xs={4}>
          <AppMenuItem onClick={handleMenuClose}>
            <BrightnessAutoTwoToneIcon
              onClick={() => {
                console.log("click 4");
              }}
              sx={{ fontSize: 36 }}
            />
            <Typography variant="caption">App 4</Typography>
          </AppMenuItem>
        </Grid>
        <Grid item xs={4}>
          <AppMenuItem onClick={handleMenuClose}>
            <ForumTwoToneIcon
              onClick={() => {
                console.log("click 5");
              }}
              sx={{ fontSize: 36 }}
            />
            <Typography variant="caption">App 5</Typography>
          </AppMenuItem>
        </Grid>
      </Grid>
    </Menu>
  );
}
