// src/componetns/Footer.tsx

import React, { FC, ReactElement } from "react";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { FatturatoCardProps, FtDataItem } from "../../interfaces";
import {
  InfoTwoTone as InfoTwoToneIcon,
  PlagiarismTwoTone as PlagiarismTwoToneIcon,
} from "@mui/icons-material";

export const FatturatoCard: FC<FatturatoCardProps> = (props): ReactElement => {
  const theme = useTheme();
  const {
    title,
    description,
    mainValue,
    iva,
    index,
    itemXs,
    data = props.data || [],
    otherInfo,
  } = props;

  return (
    <Grid item xs={itemXs} key={index}>
      <Card variant="outlined">
        <CardContent
          sx={{
            background: theme.palette.customBackground.light,
            padding: "0.5em",
            paddingBottom: "0.5em !important",
            minHeight: "14em",
          }}
        >
          <Typography variant="subtitle1" component="div">
            <b>{title}</b>
          </Typography>
          <Grid container>
            <Typography
              variant="body2"
              sx={{ fontSize: 24, fontWeight: "bold" }}
              color="evidenceColor.main"
            >
              {mainValue}
            </Typography>
            {iva ? (
              <Typography
                variant="body2"
                sx={{ fontSize: 22, fontWeight: "bold" }}
                color="text.main"
              >
                &nbsp;{iva}
              </Typography>
            ) : null}
          </Grid>
          {description ? (
            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
              {description}
            </Typography>
          ) : null}
          {otherInfo ? (
            <>
              <Typography variant="body2" mt={1}>
                <InfoTwoToneIcon sx={{ fontSize: "1.1em", marginRight: 1 }} />
                {otherInfo}
              </Typography>
            </>
          ) : null}
          {data.length > 0 ? (
            <>
              <Divider />
              <Grid container spacing={1}>
                {data.map((item: FtDataItem, index: number) => (
                  <Grid
                    item
                    xs={3}
                    key={index}
                    sx={{
                      paddingLeft: "0 !important",
                    }}
                  >
                    <Card variant="outlined" sx={{ border: "none" }}>
                      <CardContent
                        sx={{
                          background: theme.palette.customBackground.light,
                          padding: 1,
                          paddingBottom: "0 !important",
                        }}
                      >
                        {/* <Button
                          disabled
                          onClick={() => {
                            alert("Comong soon...");
                          }}
                          sx={{ color: theme.palette.text.primary }}
                          endIcon={<PlagiarismTwoToneIcon />}
                        >
                          <b>{item.label}</b>
                        </Button> */}
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: 14,
                            fontWeight: "bold",
                            marginLeft: "0.6em",
                            textTransform: "uppercase",
                          }}
                          color={
                            item.negative
                              ? "evidenceColor.contrastText"
                              : "evidenceColor.main"
                          }
                        >
                          {item.label}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: 14,
                            fontWeight: "bold",
                            marginLeft: "0.6em",
                          }}
                          color={
                            item.negative
                              ? "evidenceColor.contrastText"
                              : "evidenceColor.main"
                          }
                        >
                          {item.value}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : null}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default FatturatoCard;
