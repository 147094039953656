// src/pages/NotLogged.tsx
import React, { ReactElement, FC, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { CONFIGS } from "../configurations";
import { URL_FOR_LOGIN } from "../utilities/general";

const NotLogged: FC<any> = (): ReactElement => {
  useEffect(() => {
    document.title = `${CONFIGS.webApptitle} - User Not Logged`;
    console.log("REDIRECT TEMPORARY  SUSPENDED!! view: /pages/NotLogged.tsx");
    // window.location.replace(URL_FOR_LOGIN);
  }, []);
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={1}>
          <Typography variant="h3">Redirect to the (old) login</Typography>
        </Grid>
        <Grid item xs={12} md={1}>
          <Typography variant="h3">is temporary suspended</Typography>
        </Grid>
        <Grid item xs={12} md={1}>
          <Typography variant="h5">
            In any case, the User is no longer logged in and cannot navigate the
            new WebApp...
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NotLogged;
