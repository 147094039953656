import { ThemeOptions } from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    evidenceColor: Palette["primary"];
    bwColor: Palette["primary"];
    customBackground: Palette["primary"];
  }
  interface PaletteOptions {
    evidenceColor: PaletteOptions["primary"];
    bwColor: PaletteOptions["primary"];
    customBackground: PaletteOptions["primary"];
  }
}
export const lightTheme: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#064a71", // Header Background Color
      light: "yellow",
      contrastText: "rgba(255,255,255,0.4)",
    },
    secondary: {
      main: "#fff",
      contrastText: "red",
    },
    evidenceColor: {
      main: "#39763a",
      light: "#000",
      contrastText: "red",
    },
    bwColor: {
      main: "#000",
      light: "#fff",
      contrastText: "#ccc",
    },
    background: {
      default: "#e3e3e3", // main Background Color
      paper: "#f7f7f7",
    },
    customBackground: {
      main: "#f8f8f5",
      light: "#f1e8c8",
    },
    text: {
      primary: "rgba(0,0,0,0.7)",
      secondary: "rgba(255,255,255,1)",
      disabled: "rgba(255,255,255,0.3)",
    },
  },
  typography: {
    fontFamily: ["Quicksand", "Serif"].join(","),
  },
};
