// src/componetns/Footer.tsx

import React, { FC, ReactElement } from "react";
import DateRangePickerComponent from "./DateRangePickerComponent";
import FilterByTextComponent from "./FilterByInputTextComponent";
import FilterBySelectComponent from "./FilterBySelectComponent";
import FilterByRadioButton from "./FilterByRadioComponent";

export const Filtercomponents: FC<any> = (props): ReactElement => {
  const onFilterChange = props.onFilterChange;
  const customFilterOptions = props.customFilterOptions;
  const params = props.params;
  const { type, options } = props.params;
  switch (type) {
    case "inputText":
      return (
        <FilterByTextComponent
          filterModel={customFilterOptions}
          onFilterChange={onFilterChange}
          params={params}
          sx={{ with: "100%" }}
        />
      );
    case "select":
      return (
        <FilterBySelectComponent
          filterModel={customFilterOptions}
          onFilterChange={onFilterChange}
          params={params}
          options={options}
        />
      );
    case "radio":
      return (
        <FilterByRadioButton
          filterModel={customFilterOptions}
          onFilterChange={onFilterChange}
          params={params}
          options={options}
        />
      );
    case "dateRange":
      return (
        <DateRangePickerComponent
          filterModel={customFilterOptions}
          onFilterChange={onFilterChange}
          params={params}
        />
      );
    default:
      return <div>Error: Invalid Type for Component</div>;
  }
};

export default Filtercomponents;
